<template>
  <div class="view-home">
    <div class="container">
      <div class="quiz-status my-4">
        <div class="card" v-show="countdown">
          <ul>
            <li class="time">
              {{ countdown }}
            </li>
            <li class="recording" v-if="quiz.proctoring">
              {{ $t('testing.recording') }}
            </li>
            <li class="interruption" v-if="quiz.proctoring">
              {{ $t('testing.interruption') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container questions-wrap">
      <h2>
        {{ $t('selfesteem.title') }}
      </h2>
      <h3>
        {{ $t('selfesteem.subtitle') }}
      </h3>
      <div class="selfesteem-desc" v-html="$t('selfesteem.desc')">

      </div>


<!--      <div v-for="(question, index) in questionsSelfEsteem" :key="index" class="question-radio">-->
<!--        {{ question.text }} <br>-->
<!--        <el-radio v-model="answersSelfEsteem[index].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>-->
<!--        <el-radio v-model="answersSelfEsteem[index].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>-->
<!--        <el-radio v-model="answersSelfEsteem[index].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>-->
<!--      </div>-->
      <div class="d-flex flex-column">
        <div class="question-radio" :style="{ order: orderQuestionsSE[0] }">
          {{ orderQuestionsSE[0] }}{{ $t('selfesteem.question-1') }} <br>
          <el-radio v-model="answersSelfEsteem[0].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[0].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[0].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[1] }">
          {{ orderQuestionsSE[1] }}{{ $t('selfesteem.question-2') }} <br>
          <el-radio v-model="answersSelfEsteem[1].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[1].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[1].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[2] }">
          {{ orderQuestionsSE[2] }}{{ $t('selfesteem.question-3') }} <br>
          <el-radio v-model="answersSelfEsteem[2].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[2].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[2].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[3] }">
          {{ orderQuestionsSE[3] }}{{ $t('selfesteem.question-4') }} <br>
          <el-radio v-model="answersSelfEsteem[3].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[3].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[3].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[4] }">
          {{ orderQuestionsSE[4] }}{{ $t('selfesteem.question-5') }} <br>
          <el-radio v-model="answersSelfEsteem[4].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[4].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[4].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[5] }">
          {{ orderQuestionsSE[5] }}{{ $t('selfesteem.question-6') }} <br>
          <el-radio v-model="answersSelfEsteem[5].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[5].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[5].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[6] }">
          {{ orderQuestionsSE[6] }}{{ $t('selfesteem.question-7') }} <br>
          <el-radio v-model="answersSelfEsteem[6].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[6].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[6].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[7] }">
          {{ orderQuestionsSE[7] }}{{ $t('selfesteem.question-8') }} <br>
          <el-radio v-model="answersSelfEsteem[7].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[7].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[7].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[8] }">
          {{ orderQuestionsSE[8] }}{{ $t('selfesteem.question-9') }} <br>
          <el-radio v-model="answersSelfEsteem[8].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[8].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[8].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[9] }">
          {{ orderQuestionsSE[9] }}{{ $t('selfesteem.question-10') }} <br>
          <el-radio v-model="answersSelfEsteem[9].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[9].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[9].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[10] }">
          {{ orderQuestionsSE[10] }}{{ $t('selfesteem.question-11') }} <br>
          <el-radio v-model="answersSelfEsteem[10].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[10].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[10].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[11] }">
          {{ orderQuestionsSE[11] }}{{ $t('selfesteem.question-12') }} <br>
          <el-radio v-model="answersSelfEsteem[11].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[11].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[11].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[12] }">
          {{ orderQuestionsSE[12] }}{{ $t('selfesteem.question-13') }} <br>
          <el-radio v-model="answersSelfEsteem[12].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[12].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[12].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[13] }">
          {{ orderQuestionsSE[13] }}{{ $t('selfesteem.question-14') }} <br>
          <el-radio v-model="answersSelfEsteem[13].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[13].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[13].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[14] }">
          {{ orderQuestionsSE[14] }}{{ $t('selfesteem.question-15') }} <br>
          <el-radio v-model="answersSelfEsteem[14].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[14].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[14].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[15] }">
          {{ orderQuestionsSE[15] }}{{ $t('selfesteem.question-16') }} <br>
          <el-radio v-model="answersSelfEsteem[15].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[15].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[15].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[16] }">
          {{ orderQuestionsSE[16] }}{{ $t('selfesteem.question-17') }} <br>
          <el-radio v-model="answersSelfEsteem[16].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[16].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[16].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[17] }">
          {{ orderQuestionsSE[17] }}{{ $t('selfesteem.question-18') }} <br>
          <el-radio v-model="answersSelfEsteem[17].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[17].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[17].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[18] }">
          {{ orderQuestionsSE[18] }}{{ $t('selfesteem.question-19') }} <br>
          <el-radio v-model="answersSelfEsteem[18].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[18].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[18].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
        <div class="question-radio" :style="{ order: orderQuestionsSE[19] }">
          {{ orderQuestionsSE[19] }}{{ $t('selfesteem.question-20') }} <br>
          <el-radio v-model="answersSelfEsteem[19].choice" label="-">{{ $t('selfesteem.no') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[19].choice" label="+">{{ $t('selfesteem.yes') }}</el-radio>
          <el-radio v-model="answersSelfEsteem[19].choice" label="?">{{ $t('selfesteem.idk') }}</el-radio>
        </div>
      </div>


      <!--      <button @click="sendCollaborationResults">Отправить</button>-->
      <el-button @click="openModal"
                 class="btn btn-danger">{{ $t('testing.finish-modal-button') }}
      </el-button>

    </div>
    <b-modal id="exit-modal" hide-footer hide-header>
      <div id="modal-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('exit-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <img class="modal-warning-img mb-3" src="/images/profile/modal-warning.svg">
          <h3 class="mb-0">{{modalTitle}}</h3>
          <p>{{ $t('testing.finish-modal-desc') }}</p>
          <div class="clearfix pt-3">
            <el-button @click="sendSelfEsteemResults" class="btn btn-primary mx-2">
              {{ $t('testing.finish-modal-button') }}
            </el-button>
            <a @click="$bvModal.hide('exit-modal')"
               class="btn btn-outline-primary mx-2">{{ $t('testing.finish-modal-cancel') }}</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css';
import Loader from '../../components/Loader';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue';

Vue.use(VueViewer)
export default {
  name: "Selfesteem",
  data() {
    return {
      modalTitle: this.$t('testing.finish-modal-title'),
      timerInterval: null,
      countdown: 0,
      quiz: '',
      questionsSelfEsteem: [
        {
          numberQuestion: 1,
          text: this.$t('selfesteem.question-1')
        },
        {
          numberQuestion: 2,
          text: this.$t('selfesteem.question-2')
        },
        {
          numberQuestion: 3,
          text: this.$t('selfesteem.question-3')
        },
        {
          numberQuestion: 4,
          text: this.$t('selfesteem.question-4')
        },
        {
          numberQuestion: 5,
          text: this.$t('selfesteem.question-5')
        },
        {
          numberQuestion: 6,
          text: this.$t('selfesteem.question-6')
        },
        {
          numberQuestion: 7,
          text: this.$t('selfesteem.question-7')
        },
        {
          numberQuestion: 8,
          text: this.$t('selfesteem.question-8')
        },
        {
          numberQuestion: 9,
          text: this.$t('selfesteem.question-9')
        },
        {
          numberQuestion: 10,
          text: this.$t('selfesteem.question-10')
        },
        {
          numberQuestion: 11,
          text: this.$t('selfesteem.question-11')
        },
        {
          numberQuestion: 12,
          text: this.$t('selfesteem.question-12')
        },
        {
          numberQuestion: 13,
          text: this.$t('selfesteem.question-13')
        },
        {
          numberQuestion: 14,
          text: this.$t('selfesteem.question-14')
        },
        {
          numberQuestion: 15,
          text: this.$t('selfesteem.question-15')
        },
        {
          numberQuestion: 16,
          text: this.$t('selfesteem.question-16')
        },
        {
          numberQuestion: 17,
          text: this.$t('selfesteem.question-17')
        },
        {
          numberQuestion: 18,
          text: this.$t('selfesteem.question-18')
        },
        {
          numberQuestion: 19,
          text: this.$t('selfesteem.question-19')
        },
        {
          numberQuestion: 20,
          text: this.$t('selfesteem.question-20')
        }
      ],
      // answersSelfEsteem: [
      //   { choice: "-", question: "1" },
      //   { choice: "+", question: "2" },
      //   { choice: "-", question: "3" },
      //   { choice: "+", question: "4" },
      //   { choice: "-", question: "5" },
      //   { choice: "+", question: "6" },
      //   { choice: "-", question: "7" },
      //   { choice: "+", question: "8" },
      //   { choice: "-", question: "9" },
      //   { choice: "+", question: "10" },
      //   { choice: "-", question: "11" },
      //   { choice: "+", question: "12" },
      //   { choice: "-", question: "13" },
      //   { choice: "-", question: "14" },
      //   { choice: "+", question: "15" },
      //   { choice: "-", question: "16" },
      //   { choice: "-", question: "17" },
      //   { choice: "+", question: "18" },
      //   { choice: "-", question: "19" },
      //   { choice: "+", question: "20" },
      // ],
      answersSelfEsteem: [
        { choice: "", question: "1" },
        { choice: "", question: "2" },
        { choice: "", question: "3" },
        { choice: "", question: "4" },
        { choice: "", question: "5" },
        { choice: "", question: "6" },
        { choice: "", question: "7" },
        { choice: "", question: "8" },
        { choice: "", question: "9" },
        { choice: "", question: "10" },
        { choice: "", question: "11" },
        { choice: "", question: "12" },
        { choice: "", question: "13" },
        { choice: "", question: "14" },
        { choice: "", question: "15" },
        { choice: "", question: "16" },
        { choice: "", question: "17" },
        { choice: "", question: "18" },
        { choice: "", question: "19" },
        { choice: "", question: "20" },
      ],
      resultsSelfEsteem: '',
      orderQuestionsSE: [],
    }
  },
  methods: {
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    determineLevel(count, lang) {
      if (count >= 4) {
        // return this.$t('selfesteem.interpretation-3');
        return lang == "ru" ? "Результат свидетельствует о высокой самооценке." : "Нәтиже өзін-өзі бағалаудың жоғары екендігін көрсетеді."
      } else if (count >= -3) {
        // return this.$t('selfesteem.interpretation-2');
        return lang == "ru" ? "Результат свидетельствует об адекватной самооценке." : "Нәтиже өзін-өзі бағалаудың барабар екендігін көрсетеді."
      } else {
        // return this.$t('selfesteem.interpretation-1');
        return lang == "ru" ? "Результат свидетельствует о низкой самооценке." : "Нәтиже өзін-өзі бағалаудың төмендігін көрсетеді."
      }
    },
    openModal() {
      this.$bvModal.show('exit-modal');
    },
    async sendSelfEsteemResults() {

      let evenSum = 0;
      let oddSum = 0;

      this.answersSelfEsteem.forEach(item => {
        if (parseInt(item.question) % 2 === 0) {
          if (item.choice === "+") {
            evenSum++;
          }
        } else {
          if (item.choice === "+") {
            oddSum++;
          }
        }
      });

      this.resultsSelfEsteem = oddSum - evenSum

      const params = JSON.parse(localStorage.getItem('quiz-params'))

      let textsInterpretation = {}

      textsInterpretation.ru = this.determineLevel(this.resultsSelfEsteem, "ru") + "<br> Cамооценка становится шаблоном мышления, который изменить сложно. Рекомендуется подключить к этому процессу психолога: специалист тщательно изучит вашу личность и найдет индивидуальных подход. Резкие изменения удаются крайне редко. Это планомерный процесс фиксации своего внимания на фактах: результаты действий, решений, реакции окружающих. При низком уровне самовосприятия/самооценки человеку необходимо акцентировать свои достижения, достоинства, похвалу со стороны. В случае высокой самооценки необходимо вопреки гордости чаще спрашивать совета у друзей, родителей, педагогов. При любом уровне самооценки очень важно изучать себя, свои вкусы, предпочтения, развивать смелость пробовать что-то новое. В этом возрасте появляются новые увлечения, могут меняться ценности и вкусы, хорошо расширять свой опыт. Самооценка основывается не только на достижениях, но и на умении принимать себя, если постигла в чем-то неудача."
      textsInterpretation.kz = this.determineLevel(this.resultsSelfEsteem, "kz") + "<br> Өзін-өзі бағалау ойлау үлгісіне (шаблонына)айналады, оны өзгерту қиын. Бұл процеске психологты қосу ұсынылады: маман сіздің жеке басыңызды мұқият зерттейді және жеке тәсілді табады. Күрт өзгерістер өте сирек кездеседі. Бұл фактілерге назар аударудың жоспарлы процесі: іс-әрекеттердің нәтижелері, шешімдер, қоршаған орта реакциялары. Өзін-өзі бағалау төмен бөлған жағдайда, жетістіктеріне, қадір-қасиетіне, мақтаныштарына баса назар аударуы керек. Өзін-өзі бағалауы жоғары болған жағдайда, мақтанышқа қарамастан, достардан, ата-аналардан, мұғалімдерден жиі кеңес сұрау керек. Өзін-өзі бағалаудың кез келген деңгейінде өзіңді, талғамыңды, қалауларыңды зерделеу және жаңа нәрсені байқап көруге батылдықты дамыту өте маңызды. Бұл жаста жаңа хобби пайда болады, құндылықтар мен талғамдар өзгеруі мүмкін және тәжірибеңді молайтуға жақсы мүмкіндік бар. Өзін-өзі бағалау тек жетістіктерге ғана емес, сонымен қатар егер сен қандай да бір сәтсіздікке ұшырасаң да өзіңді қабылдай білуге негізделген."

      if (this.resultsSelfEsteem <= 3) {
        textsInterpretation.ru = textsInterpretation.ru + "<br> Если у вас высокий уровень самокритики его нужно научиться балансировать с саморазвитием. Но также умение благодарить себя и окружающих должно быть не менее часто, чем самокритика или критика окружающего. Так формируется адекватная самооценка, поскольку если пользоваться только самокритикой, возникает лишь иллюзия реалистического восприятия мира. Об этом тоже стоит помнить и уделять этому внимание."
        textsInterpretation.kz = textsInterpretation.kz + "<br> Егер сенде өзін-өзі сынға алу деңгейі жоғары болса, оны өзін-өзі дамытумен теңестіруді үйрену керек. Сонымен қатар, өзіңе және басқаларға алғыс айту қабілеті өзін-өзі сынаудан немесе басқаларды сынаудан кем болмауы керек. Адекватты өзін-өзі бағалау осылай қалыптасады, өйткені егер сен тек өз-өзіңді сынасаң, әлемді шынайы қабылдаудың елесі ғана пайда болады. Мұны да есте ұстаған және назар аударған жөн."
      }

      const selfEsteemSendAnswers = [
        this.answersSelfEsteem, {resultsSelfEsteem: this.resultsSelfEsteem}, {textsInterpretation},
      ]

      params.answer_id = {...params.answer_id, selfEsteemSendAnswers}

      // params.answer_id.push(selfEsteemSendAnswers)

      localStorage.setItem('quiz-params', JSON.stringify(params))
      const quizToken = localStorage.getItem('quiz-token')
      const userQuizId = localStorage.getItem('user_quiz_id')

      const response = await this.$http.post(API_ROOT + '/api/user-answer/' + quizToken, params)

      if (response.body.status == 'success') {

        Vue.toastr({
          message: this.$t('successful'),
          description: this.$t('saved-successfully'),
          type: 'success'
        })

        localStorage.removeItem('timerCollaboration')
        localStorage.removeItem('timerCriticalThinking')
        localStorage.removeItem('timerСommunications')
        localStorage.removeItem('timerСreativity')
        localStorage.removeItem('timerSelfEsteem')

        window.location.href=`${window.QABILET_ROOT}/test-results-new/${userQuizId}/${quizToken}`
      } else {
        Vue.toastr({
          message: this.$t('error-title'),
          description: this.$t('error-saving'),
          type: 'error'
        })
      }

      console.log('params', params)

    },
    startTimer(duration) {
      let timer = duration, minutes, seconds;
      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.countdown = minutes + ":" + seconds;

        let savedTimer = (parseInt(minutes) * 60) + parseInt(seconds);
        localStorage.setItem('timerSelfEsteem', savedTimer);

        if (--timer < 0) {
          clearInterval(this.timerInterval);
          this.sendCollaborationResults()
          // this.saveAnswer();
          // this.exit();
          // timer = duration;
        }
      }, 1000);
    }
  },
  mounted() {
    if (localStorage.getItem('timerSelfEsteem')) {
      this.startTimer(localStorage.getItem('timerSelfEsteem'))
    } else {
      this.startTimer(300)
    }

    let test_lang = localStorage.getItem('test_lang')

    const array = [];
    for (let i = 1; i <= 20; i++) {
      array.push(i);
    }

    this.orderQuestionsSE = this.shuffleArray(array)

    if (test_lang){
      this.$i18n.locale = test_lang;
    }
  }
}
</script>

<style>

.selfesteem-desc {
  margin: 20px 0;
}

</style>
